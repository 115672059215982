import React, { useState, useEffect } from 'react';
import { isValidDateTime, toDateTimeString } from '../../../support/date';
import { CALL_ROW } from '../../../support/cypressTags';
import EllipsisText from '../EllipsisText';
import DatePicker from './DatePicker';

/**
 * Inline date picker
 * @param {number} occurrenceDate: A number representing the milliseconds elapsed between
 *    1 January 1970 00:00:00 UTC and the given value.
 * @param {function} onSave: hook for saving an updated Date
 * @param {Object} styles: provided styling classes
 * @returns {JSX.Element}
 * @constructor
 */
const CallDate = ({ occurrenceDate, onSave, styles }) => {
  // States
  const [date, setDate] = useState(new Date(occurrenceDate));
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setDate(new Date(occurrenceDate));
  }, [occurrenceDate]);

  const handleOnCloseDatePicker = (_date) => {
    setDate(_date);
    setEditing(false);

    onSave && onSave({ occurrence_date: _date.getTime() });
  };

  return (
    <>
      {editing ? (
        <td data-cy={CALL_ROW.DATE}>
          <DatePicker occurrenceDate={occurrenceDate} onClose={handleOnCloseDatePicker} />
        </td>
      ) : (
        <td data-cy={CALL_ROW.DATE} className={styles.date} onClick={() => setEditing(true)}>
          <div>
            <time dateTime={isValidDateTime(date) ? date.toISOString() : ''}>
              <EllipsisText text={toDateTimeString(date)} />
            </time>
          </div>
        </td>
      )}
    </>
  );
};

export default CallDate;
